import API from 'libs/api'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'submitButton',
    'textEditor',
    'uploader',
    'form',
    'attachedVideoPreview',
    'attachedVideoIdField',
    'attachedVideoPreviewTitle',
    'attachedVideoPreviewDesc',
    'attachedVideoPreviewDuration',
    'attachedVideoPreviewImg',
    'emojiDropdown',
    'gifsDropdown',
    'gifsUploader',
    'gifUrl'
  ]

  initialEditorValue = ''

  initialGifUrl = ''

  initialVideoValue = {}

  connect() {
    this.initialEditorValue = this.textEditorTarget.value
    this.initialGifUrl = this.hasGifUrlTarget ? this.gifUrlTarget.value : ''
    this.initialVideoValue = {
      id: this.attachedVideoIdFieldTarget.value,
      title: this.attachedVideoPreviewTitleTarget.textContent,
      desc: this.attachedVideoPreviewDescTarget.textContent,
      duration: this.attachedVideoPreviewDurationTarget.textContent,
      img: this.attachedVideoPreviewImgTarget.src
    }
  }

  activate() {
    this.checkButtonState()
  }

  handleContentChange() {
    requestAnimationFrame(() => {
      this.checkButtonState()
    })
  }

  handleFilesAdded() {
    this.textEditorTarget.focus()
    this.imgLoading = true
    this.checkButtonState()
  }

  handleFilesLoaded() {
    this.imgLoading = false
    this.checkButtonState()
  }

  handleFilesRemoved() {
    this.textEditorTarget.focus()
    this.checkButtonState()
  }

  handleAttachedVideoChanged() {
    this.textEditorTarget.focus()
    this.checkButtonState()
  }

  async handleSuggestionsSearch(e) {
    const formDiv = this.formTarget.closest('.mention-form')
    const { query, resolve } = e.detail
    const params = { query }
    const { mentionSuggestionsUrl } = formDiv.dataset

    Object.keys(formDiv.dataset).forEach(dataAttr => {
      if (dataAttr.includes('mention') && formDiv.dataset[dataAttr]) {
        const parameter = dataAttr.replace('mention', '')
        const snakeCaseParameter = parameter.replace(/[A-Z]/g, (letter, index) => {
          if (index === 0) {
            return letter.toLowerCase()
          }

          return `_${letter.toLowerCase()}`
        })

        params[snakeCaseParameter] = formDiv.dataset[dataAttr]
      }
    })

    const response = await API.get((mentionSuggestionsUrl || '/api/community/mentions'), { params })
    resolve(response.data)
  }

  onEmojiSelect(e) {
    this.textEditorTarget.append(e.detail)
    this.textEditorTarget.focus('current')
    this.emojiDropdownTarget.close()
  }

  onGifSelect(e) {
    this.gifUrlTarget.value = e.detail
    this.gifsDropdownTarget.close()
    this.gifsUploaderTarget.addURLs([e.detail])
    this.textEditorTarget.focus()
    this.checkButtonState()
  }

  handleGifRemoved() {
    this.gifUrlTarget.value = ''
    this.textEditorTarget.focus()
    this.checkButtonState()
  }

  toggleClasses() {
    if (this.element.dataset.type === 'comment') {
      this.controlContainerTarget?.classList.add('hidden')
    } else if (this.element.dataset.type === 'edit_comment') {
      document.querySelector(`#comment-body-${this.element.dataset.commentId}`).classList.remove('hidden')
      this.element.classList.add('hidden')
    } else if (this.element.dataset.type === 'reply') {
      //
    } else {
      this.element.classList.add('hidden')
    }
  }

  handleCancel() {
    this.textEditorTarget.value = this.initialEditorValue
    this.attachedVideoIdFieldTarget.value = this.initialVideoValue.id
    this.attachedVideoPreviewTitleTarget.textContent = this.initialVideoValue.title
    this.attachedVideoPreviewDescTarget.textContent = this.initialVideoValue.desc
    this.attachedVideoPreviewDurationTarget.textContent = this.initialVideoValue.duration
    this.attachedVideoPreviewImgTarget.src = this.initialVideoValue.img
    if (this.initialVideoValue.title.length > 0) {
      this.attachedVideoPreviewTarget.classList.remove('hidden')
    } else {
      this.attachedVideoPreviewTarget.classList.add('hidden')
    }
    this.imgLoading = false
    this.submitButtonTarget.disabled = false
    if (this.hasGifsUploaderTarget) {
      this.gifsUploaderTarget.reset()
    }
    this.toggleClasses()
    if (this.hasGifUrlTarget) {
      this.gifUrlTarget.value = this.initialGifUrl
    }
    window.requestAnimationFrame(() => {
      this.checkButtonState()
    })
  }

  checkButtonState() {
    const hasValue = typeof this.textEditorTarget.dataset.hasValue !== 'undefined'
    const hasImage = typeof this.uploaderTarget.dataset.hasFiles !== 'undefined'
    const hasVideo = !!this.attachedVideoIdFieldTarget.value
    const hasGif = this.hasGifsUploaderTarget && typeof this.gifsUploaderTarget.dataset.hasFiles !== 'undefined'
    const hasContent = hasValue || hasImage || hasVideo || hasGif
    this.submitButtonTarget.disabled = this.imgLoading || !hasContent
    this.element.dataset.hasValue = hasContent
  }
}
